import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface PageTemplateProps {
  data: {
    page: GatsbyTypes.WpProfiel
  }
  location?: any
  prefix?: string
}

export interface VacancyContextProps {
  // eslint-disable-next-line
  preview: GatsbyTypes.WpProfiel_Profilepreview
  title: string
}

export const ProfileContext = React.createContext({})

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: {
      title,
      profilepreview,
      databaseId,
      flexContent,
      seo,
      locale,
      translations,
    },
  },
  location = {},
  prefix = 'Profiel_Flexcontent',
}) => (
  <ProfileContext.Provider
    value={{
      preview: profilepreview,
      title,
    }}
  >
    <Layout
      pageId={databaseId}
      locale={locale?.locale}
      translations={translations}
    >
      <SEO seo={seo} />
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
      />
    </Layout>
  </ProfileContext.Provider>
)

export const pageQuery = graphql`
  query profileById($pageId: String!) {
    page: wpProfiel(id: { eq: $pageId }) {
      ...generalProfileFragment
    }
  }
`

export default PageTemplate
